export class ConstanteAngular {

    public static APLICATION_DEFAULT = 'APP-0002'
    public static TYPE_USER_DEFAULT = 'PAR-0011'
    public static JWT_RESPONSE_HEADER = 'token';
    public static JWT_RESPONSE_USER = 'user';
    public static JWT_RESPONSE_PROFILE = 'pv';
    public static CC_RESPONSE_HEADER = 'cc';
    public static TYPE_USER = 'TYPE_USER';

    //ACCIONES
    public static ACCION_SOLICITADA_LISTAR = "LISTAR";
    public static ACCION_SOLICITADA_NUEVO = "NUEVO";
    public static ACCION_SOLICITADA_EDITAR = "EDITAR";
    public static ACCION_SOLICITADA_ANULAR = "ANULAR";
    public static ACCION_SOLICITADA_ELIMINAR = "ELIMINAR";
    public static ACCION_SOLICITADA_DESCONFIRMAR='DESCONFIRMAR'
    public static ACCION_SOLICITADA_VER = "VER";
    public static ACCION_SOLICITADA_COPIAR = 'COPIAR';
    public static ACCION_SOLICITADA_GUARDAR = 'GUARDAR';
    public static ACCION_SOLICITADA_SALIR = 'SALIR';
    public static ACCION_SOLICITADA_BUSCAR = "BUSCAR";

    //MENU SEGURIDAD
    public static MENUSEGURIDAD = 'menu-seguridad';

    //FILTROS
    public static FILTROSESION = 'filtrosession';
    public static RUTA = 'ruta';

    //Combos
    public static COMBOTODOS = 'Todos';
    public static COMBOTODOS_NULL = ' ';
    public static COMBOSELECCIONE = 'Seleccione';

}

