import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ConstanteAngular } from '../app/framework/angular/funciones/ConstanteAngular';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];

    ngOnInit() {

            this.model = [
                {
                    label: 'Proveedores',
                    icon: 'pi pi-home',
                    items: [
                        {
                            label: 'Documentos',
                                    icon: 'pi pi-user-edit',
                                    items: [
                                        {
                                            label: 'Seguimiento de O/S',
                                            icon: 'pi pi-fw pi-list',
                                            routerLink: ['/ab/seguimiento_os_list']
                                        }
                                    ]
                        },
                        {
                            label: 'Manual de Usuario',
                            icon: 'pi pi-fw pi-book',
                            routerLink: ['/ab/manual_os_list']
                        }
                    ],
                } 
            ]
    }
}
