
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstanteAngular } from 'src/app/app/framework/angular/funciones/ConstanteAngular';
import { AppConfig } from 'src/environments/appconfig';
import { environment } from 'src/environments/environment';

const URL_LOGIN = environment.web.login;
@Injectable()
export class LoginService {

    private urlApi: string = environment.urlBase;
    private urlSeguridadApi: string = environment.urlBaseSeguridad;

    constructor(private config: AppConfig) { }

    public ingresar(user: any): any {
        return this.config.getHttp().post(`${this.urlSeguridadApi}Authentication/login`, user)
    }

    public getRol(user:any):any{            
      
        return this.config.getHttp().post(`${this.urlSeguridadApi}Profile/ByUsuId`, user)
      }

    public obtenerCCxUsuario(user: any): any {
        return this.config.getHttp().post(`${this.urlApi}PoiMeta/listar`, user)
    }

    getSession(): any {
        return this.config.getHttp().post(`${this.urlSeguridadApi}Session/getSession`, {});
    }

    public SaveCentroCostos(res: any,user:string) {
        const token = res.oData[0].codigo;
        if (token) {
            localStorage.setItem(ConstanteAngular.CC_RESPONSE_HEADER, token);
            localStorage.setItem(ConstanteAngular.TYPE_USER, user);
        }
        return res;
    }

    public saveToken(res: any): any {
        const token = res;
        if (token) {
            localStorage.setItem(ConstanteAngular.JWT_RESPONSE_HEADER, JSON.stringify(token));
        }
        return res;
    }

    public saveUser(res: any): any {
        const token = res;
        if (token) {
            localStorage.setItem(ConstanteAngular.JWT_RESPONSE_USER, token);
            localStorage.setItem(ConstanteAngular.JWT_RESPONSE_PROFILE, "pv");
        }
        return res;
    }

    public readToken() {
        let token = localStorage.getItem(ConstanteAngular.JWT_RESPONSE_HEADER);
        if (token != null)
            return JSON.parse(token)
        else
            null
    }

    public checkLogin(): Observable<boolean> {
        return new Observable((observer) => {
            if (this.isAuthenticated()) {
                observer.next(true);
                observer.complete();
            } else {
                observer.error('El usuario no está autenticado');
            }
        });
    }

    private isAuthenticated() {
        let token = this.readToken() 

        if(token!=null){
            return true;
        }

        return false;     
    }
    goToLogin(){
        localStorage.removeItem(ConstanteAngular.JWT_RESPONSE_HEADER)
        //window.location.href = URL_LOGIN;
    }
}
