import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { map, catchError, of } from "rxjs";
import { LoginService } from "src/app/demo/service/login.service";
import { ConstanteAngular } from "../funciones/ConstanteAngular";

export namespace AuthGuard {
    export const canActivate = (
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot
    ) => {
        const authService = inject(LoginService);
        const router = inject(Router);

        return authService.checkLogin().pipe(
                map(() => {return true}),
                catchError(() => {  
                    authService.goToLogin();     
                    localStorage.removeItem(ConstanteAngular.JWT_RESPONSE_HEADER)
                    return of(false);
                }
            )
        );
    }

    export const canActivateChild = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) => canActivate(route, state);
}