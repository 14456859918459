import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { StoreModule } from '@ngrx/store';
import { ToastModule } from 'primeng/toast';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';
import { MessageService } from 'primeng/api';
import { AppConfig } from 'src/environments/appconfig';
import { NoAuthorizationInterceptor } from './app/framework/angular/interceptor/NoAuthorizationInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProgressBarService } from './layout/service/progressBarService.service';
import { ProgressBarInterceptor } from './app/framework/angular/interceptor/ProgressBarInterceptor';
import { LoginService } from './demo/service/login.service';


@NgModule({
    declarations: [
        AppComponent       
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        ToastModule,
        StoreModule.forRoot( appReducers ),
        StoreDevtoolsModule.instrument({
          maxAge: 25// Retains last 25 states// Restrict extension to log-only mode
        }),
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS,useClass: ProgressBarInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useExisting: NoAuthorizationInterceptor, multi: true },

        LoginService,
        NoAuthorizationInterceptor,
        ProgressBarService,
        MessageService,
        AppConfig,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


