import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private progressSubject = new BehaviorSubject<number>(0);
  
  private progress = new BehaviorSubject<number>(0);
  progress$ = this.progress.asObservable();

  setProgress(value: number) {
    this.progress.next(value);
  }

  // Observable para controlar el estado de carga
  isLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  // Observable para controlar el progreso
  getProgress(): Observable<number> {
    return this.progressSubject.asObservable();
  }

  // Inicia el ProgressBar
  startLoading() {
    this.loadingSubject.next(true);
  }

  // Detiene el ProgressBar
  stopLoading() {
    this.loadingSubject.next(false);
    this.progressSubject.next(0);
  }

  // Actualiza el progreso del ProgressBar
  updateProgress(progress: number) {
    this.progressSubject.next(progress);
  }
}
