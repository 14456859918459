import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {

  private config: { [key: string]: any } = {};
  private env: { [key: string]: any } = {};

  constructor(private http: HttpClient) {
  }

  getHttp(): HttpClient {
    return this.http;
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: string) {
    return this.config[key];
  }


  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */


}
