import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ProgressBarService } from './service/progressBarService.service';
import { AppConfig } from 'src/environments/appconfig';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {
    timeout: any = null;
    showProgressBar = false;
    progressValue = 100;

    private urlRecursos: string = environment.recursoImagenes;
    
    logo:string= `assets/images/${this.urlRecursos}/ceplan.png`;

    @ViewChild('menuContainer') menuContainer!: ElementRef;
    constructor(public layoutService: LayoutService, public el: ElementRef,
        public progressBarService: ProgressBarService, private config: AppConfig) {
        this.progressBarService.progress$.subscribe((progress) => {
            this.progressValue = progress;
            if (this.progressValue == 0)
                this.showProgressBar = true
            else if (this.progressValue == 100)
                this.showProgressBar = false
        });
    }

    onMouseEnter() {
        if (!this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;


        }
    }

    onMouseLeave() {
        if (!this.layoutService.state.anchored) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => this.layoutService.state.sidebarActive = false, 300);
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
    }

}
