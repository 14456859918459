import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ConstanteAngular } from '../app/framework/angular/funciones/ConstanteAngular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const URL_LOGIN = environment.web.login;

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    usuario: any

    constructor(public layoutService: LayoutService, private router: Router) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    ngOnInit(): void {
        this.usuario = JSON.parse(String(localStorage.getItem(ConstanteAngular.JWT_RESPONSE_HEADER)))
    }

    cerrarSesion() {
        window.location.href = URL_LOGIN;
    }
}