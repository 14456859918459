import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, finalize, interval, takeUntil, tap, timer } from 'rxjs';
import { ProgressBarService } from 'src/app/layout/service/progressBarService.service';


@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {
    constructor(private progressBarService: ProgressBarService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        this.progressBarService.setProgress(0); // Inicializa el progreso en 0 al comenzar la solicitud
    
        return next.handle(request).pipe(
          finalize(() => {
            // Incrementa el progreso en 100 una vez que la solicitud está completa
            this.progressBarService.setProgress(100);
          })
        );
      }
}
