import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from "rxjs";
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConfig } from '../../../../../environments/appconfig';
import { ConstanteAngular } from '../funciones/ConstanteAngular';
import { environment } from 'src/environments/environment';


@Injectable()
export class NoAuthorizationInterceptor implements HttpInterceptor {

    constructor(private messageService: MessageService, private route: Router, private config: AppConfig) {

    }

    dataStream: ReplaySubject<any> = new ReplaySubject<any>();
    mensajeAnteiror: string = "";

    dataStream$(): Observable<any> {
        this.dataStream = new ReplaySubject<any>();
        return this.dataStream.asObservable();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let accessToken = this.readToken();
        let token = accessToken?.sToken

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                sid: `${this.config.getEnv('sid')}`,
            }
        });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // Manejar el error aquí, por ejemplo, loggearlo o mostrar una notificación al usuario      
                return throwError(() => this.handleError(request, error));
            }),
            finalize(() => {
                this.handleResponse(request)
            })
        );
    }

    readToken(): any {
        let token = String(localStorage.getItem(ConstanteAngular.JWT_RESPONSE_HEADER))

        if (token == null || token == undefined)
            return

        let accessToken = JSON.parse(token);
        return accessToken
    }

    handleResponse(req: HttpRequest<any>) {

    }

    handleError(req: HttpRequest<any>, errorResponse: HttpErrorResponse) {

        if (errorResponse.status === 407) {
            //407 Session expirada
            this.mostrarMensajesError("Su sesión ha caducado. Vuelva a iniciar sesión");
            //localStorage.clear()
            this.route.navigate([""]);
        }

        if (errorResponse.status === 504) {
            //504 Servidor de seguridad apagado
            this.mostrarMensajesError("Los servicios no están activos, reintentar en unos minutos");
        }

        if (errorResponse.status === 0) {
            //0 Api actual apagada
            this.mostrarMensajesError("Los servicios no están activos, reintentar en unos minutos");
        }

        if (errorResponse.status === 500) {
            //500 Error de aplicación no controlado
            this.mostrarMensajesError(errorResponse);
            // this.route.navigate([""]);
        }

        if (errorResponse.status === 401) {
            //401, lo mandamos al login
            this.mostrarMensajesError('No tiene accesos, debe ingresar sus credenciales');
            //localStorage.clear()     
            this.route.navigate([""]);
        }
    }

    mostrarMensajesError(errorResponse: any): void {
        if (Array.isArray(errorResponse.error)) {
            for (var _i = 0; _i < errorResponse.error.length; _i++) {
                this.mostrarMensajeNoDuplicado(errorResponse.error[_i].mensaje);
            }
            return;
        }
        if (typeof errorResponse === 'string') {
            this.mostrarMensajeNoDuplicado(errorResponse);
        }
        else {
            this.mostrarMensajeNoDuplicado(errorResponse.error.message);
        }
    }

    mostrarMensajeNoDuplicado(mensaje: string) {
        if (this.mensajeAnteiror !== mensaje) {
            this.mensajeAnteiror = mensaje;
            this.mostrarMensaje(mensaje, 'error');
        }
    }

    mostrarMensaje(mensaje: string, tipo: string, callBack?: () => void): void {
        this.messageService.add({ severity: tipo, summary: 'Mensaje', detail: mensaje });
    }
}