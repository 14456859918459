export const environment = {
  production: true,
  urlBase:"https://api-abastecimiento-dev.ceplan.gob.pe/api/",    
  urlBaseSeguridad:"https://api-sso-security-dev.ceplan.gob.pe/api/",
  urlBaseFileDrive:"https://api-file-drive-dev.ceplan.gob.pe/api/",
  recursoImagenes: "logos",
  web:{
    login: 'https://login-dev.ceplan.gob.pe'
},
};
